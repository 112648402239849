// src/templates/posts/PressRelease.js

import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import PageTitle from '../../components/PageTitle'
import ResourceMetadata from '../../components/ResourceMetadata'
import parse from 'html-react-parser'

function PressRelease({data}) {
  const postData = data.wpPressRelease

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let postTags = []

      const gtmArgs = {
        pageType: 'press release',
        publishDate: postData.date,
        tags: postTags,
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(gtmArgs)
    }
  }, [postData])

  const sourceStr = function () {
    if (postData.pressRelease.source.sourceUrl) {
      return (
        <a href={postData.pressRelease.source.sourceUrl}>
          {postData.pressRelease.source.sourceName}
        </a>
      )
    } else {
      return postData.pressRelease.source.sourceName
    }
  }

  const articleSource = sourceStr()

  return (
    <Layout>
      <Seo post={postData}/>

      <article className="space-y-responsive-xl-48">
        <section className="press-title">
          <PageTitle title={postData.title} label="Press Release"/>

          <aside className="text-center">
            {articleSource && (
              <div className="author mb-responsive-xl-8">
                <span className="mr-responsive-xl-4 align-baseline font-medium text-xs uppercase tracking-wider">
                  Source:
                </span>
                  <span className="align-baseline font-regular text-sm">
                  {articleSource}
                </span>
              </div>
            )}

            <div
              className="related-topics flex items-center justify-center divide-x divide-granite-200 font-medium text-xs uppercase tracking-wider">
              <span className="px-responsive-xl-8">{postData.date}</span>
              <span className="px-responsive-xl-8">
                {postData.pressRelease.location}
              </span>
            </div>
          </aside>
        </section>

        <div className="container-inner">
          {postData.content && (
            <div className="wysiwyg text space-y-responsive-xl-32">
              {parse(postData.content)}
            </div>
          )}
        </div>

        {postData.pressRelease.contactInformation && (
          <section className="press-contact">
            <div className="container-inner">
              <h3
                className="mb-responsive-xl-32 border-b border-granite-200 pb-responsive-xl-8 text-center font-regular text-sm uppercase tracking-widest">
                Contact
              </h3>
              <div className="press-contact-inner space-y-responsive-xl-16">
                <p>
                  <strong>Name: </strong>
                  {postData.pressRelease.contactInformation[0].name}
                </p>
                <p>
                  <strong>Email: </strong>
                  <a
                    href={
                      'mailto:' +
                      postData.pressRelease.contactInformation[0].email
                    }
                  >
                    {postData.pressRelease.contactInformation[0].email}
                  </a>
                </p>
                <p>
                  <strong>Phone: </strong>
                  {postData.pressRelease.contactInformation[0].phoneNumber}
                </p>
              </div>
            </div>
          </section>
        )}
      </article>
    </Layout>
  )
}

export default PressRelease

export const query = graphql`
  query ($id: String!) {
    wpPressRelease(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      title
      date(formatString: "M/D/YYYY")
      content
      pressRelease {
        location
        source {
          sourceName
          sourceUrl
        }
        contactInformation {
          name
          email
          phoneNumber
        }
      }
    }
  }
`
